import React from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { AuthGuard } from './features/auth/auth.guard';
import { AuthProvider } from './features/auth/auth.provider';
import { Login } from './features/auth/login/login.component';
import { CustomerRoutes } from './features/customer/routes.component';
import { Dashboard } from './features/dashboard/dashboard.component';
import { ProductionRoutes } from './features/production/routes.component';
import './global.scss';

export const App = () => {
  let navigate = useNavigate();

  return (
    <AuthProvider>
      <Routes>
        <Route path="/" element={<Navigate to="/dashboard" replace />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/customers/*" element={<CustomerRoutes />} />
        <Route path="/productions/*" element={<ProductionRoutes />} />
        <Route path="/login" element={<Login />} />
      </Routes>

      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <AuthGuard />
    </AuthProvider>
  );
};
