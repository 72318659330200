import React, {
  ComponentType,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { Dimmer } from 'semantic-ui-react';
import { useApi } from '../api/api.hooks';

interface AuthApi {
  profile: API.Administrator | false | undefined;
  loading: boolean;
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
}
const AuthContext = createContext<AuthApi | null>(null);

export const AuthProvider: ComponentType = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState<
    API.Administrator | false | undefined
  >();
  const navigate = useNavigate();
  const { getOne, post } = useApi();

  const refreshProfile = useCallback(() => {
    setLoading(true);
    getOne('auth/profile')
      .then((result) => {
        const profile = result;
        if (profile.type === 'administrator') {
          setProfile(profile);
        } else {
          setProfile(false);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setProfile(false);
      });
  }, []);

  const login = useCallback(async (email: string, password: string) => {
    try {
      setLoading(true);
      await post('auth/login', { email, password });
      await refreshProfile();
      //navigate('/');
    } catch (e) {
      setLoading(false);
      setProfile(false);
    }
  }, []);

  const logout = useCallback(async () => {
    await post('auth/logout');
    navigate('/login');
  }, []);

  const api = {
    profile,
    login,
    logout,
    loading,
  };

  useEffect(() => {
    refreshProfile();
  }, []);

  return (
    <AuthContext.Provider value={api}>
      {profile !== undefined ? children : <Dimmer active />}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('AuthContext not ready');
  }
  return context;
}
