import { useAuth } from '@/features/auth/auth.provider';
import classNames from 'classnames';
import React, { ComponentType, useState } from 'react';
import { Link } from 'react-router-dom';
import { Icon, Menu as SemanticMenu, Sidebar } from 'semantic-ui-react';
import styles from './menu.module.scss';

interface MenuProps {}

export const Menu: ComponentType<MenuProps> = (props) => {
  const { children } = props;
  const [visible, setVisible] = useState(false);
  const { logout } = useAuth();
  return (
    <Sidebar.Pushable>
      <Sidebar
        as={SemanticMenu}
        animation="overlay"
        icon="labeled"
        inverted
        onHide={() => setVisible(false)}
        vertical
        visible={visible}
        width="thin"
      >
        <SemanticMenu.Item as={Link} to="/dashboard">
          <Icon name="dashboard" />
          Dashboard
        </SemanticMenu.Item>
        <SemanticMenu.Item as={Link} to="/customers">
          <Icon name="user" />
          Kunden
        </SemanticMenu.Item>
        <SemanticMenu.Item as={Link} to="/productions">
          <Icon name="user" />
          Produktionen
        </SemanticMenu.Item>
        <SemanticMenu.Item onClick={logout}>
          <Icon name="power off" />
          Abmelden
        </SemanticMenu.Item>
      </Sidebar>

      <Sidebar.Pusher dimmed={visible}>
        <Icon
          size="big"
          onClick={() => setVisible((v) => !v)}
          className={classNames(
            styles.toggleButton,
            visible && styles.sidebarOpen
          )}
          name="bars"
        />
        {children}
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  );
};
