import { useApiEntity } from '@/features/api/api.hooks';
import { Form } from '@/features/form/controlled.component';
import { Page } from '@/features/frame/page/page.component';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { ComponentType, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Menu } from 'semantic-ui-react';
import schema from './schema';

interface DetailProps {}

export const Detail: ComponentType<DetailProps> = (props) => {
  const { id } = useParams<{ id: string }>();

  const navigate = useNavigate();

  const { data, update } = useApiEntity<API.Customer>('admin/customers', {
    id,
    skip: !id,
  });

  const form = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const onSave = useMemo(
    () =>
      form.handleSubmit(
        (data) => {
          if (id) {
            update(data, parseInt(id));
          }
        },
        (error) => {}
      ),
    [id]
  );

  return (
    <Page title="Kunde">
      <Menu size="small">
        <Menu.Menu position="right">
          <Menu.Item>
            <Button onClick={() => navigate('..')}>Zurück</Button>
            <Button primary onClick={onSave}>
              Speichern
            </Button>
          </Menu.Item>
        </Menu.Menu>
      </Menu>
      <Form form={form} values={data}>
        <Form.Input label="E-Mail" name="email" />
        <Form.Input label="Vorname" name="firstName" />
        <Form.Input label="Nachname" name="lastName" />
        <Form.Input label="Geschlecht" name="gender" />
        <Form.Input label="Firma" name="company" />
        <Form.Input label="Straße und Hausnr." name="streetAndNumber" />
        <Form.Input label="PLZ" name="postalCode" />
        <Form.Input label="Stadt" name="city" />
        <Form.Input label="Land" name="country" />
        <Form.Input label="Geburtsdatum" name="dateOfBirth" />
      </Form>
    </Page>
  );
};
