// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pI7T0JqWQQm-eES4DMWnEA\\=\\={cursor:pointer;position:fixed;z-index:10;top:20px;left:20px;width:200px;height:200px;transition:all .4s}.pI7T0JqWQQm-eES4DMWnEA\\=\\=.AEiCi4rt6XPGj1K0jTHh9Q\\=\\={left:180px}", "",{"version":3,"sources":["webpack://./src/features/frame/menu/menu.module.scss"],"names":[],"mappings":"AAAA,4BACE,cAAA,CACA,cAAA,CACA,UAAA,CACA,QAAA,CACA,SAAA,CACA,WAAA,CACA,YAAA,CACA,kBAAA,CACA,uDACE,UAAA","sourcesContent":[".toggleButton {\n  cursor: pointer;\n  position: fixed;\n  z-index: 10;\n  top: 20px;\n  left: 20px;\n  width: 200px;\n  height: 200px;\n  transition: all 0.4s;\n  &.sidebarOpen {\n    left: 180px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toggleButton": "pI7T0JqWQQm-eES4DMWnEA==",
	"sidebarOpen": "AEiCi4rt6XPGj1K0jTHh9Q=="
};
export default ___CSS_LOADER_EXPORT___;
