// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TySeZo6lOyi675-B4E-i2A\\=\\={display:flex;align-items:center;justify-content:center;height:100vh}", "",{"version":3,"sources":["webpack://./src/features/auth/login/login.module.scss"],"names":[],"mappings":"AAAA,4BACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,YAAA","sourcesContent":[".host {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"host": "TySeZo6lOyi675-B4E-i2A=="
};
export default ___CSS_LOADER_EXPORT___;
