import { ComponentType, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from './auth.provider';

export const AuthGuard: ComponentType = ({ children }) => {
  const { profile, loading } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (profile === false && !loading && location.pathname !== '/login') {
      navigate('/login');
    } else if (location.pathname === '/login' && profile) {
      navigate('/');
    }
  }, [profile, location, loading]);

  return null;
};
