import { yupResolver } from '@hookform/resolvers/yup';
import React, { ComponentType, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Form } from 'semantic-ui-react';
import { object, string } from 'yup';
import { useAuth } from '../auth.provider';
import styles from './login.module.scss';

const schema = object({
  email: string().email().required(),
  password: string().required(),
});
interface LoginProps {}

export const Login: ComponentType<LoginProps> = (props) => {
  const form = useForm({
    defaultValues: {
      email: process.env.NODE_ENV === 'development' ? 'admin@fakeos.de' : '',
      password: process.env.NODE_ENV === 'development' ? '123' : '',
    },
    resolver: yupResolver(schema),
  });
  const { login } = useAuth();

  const onSubmit = useMemo(
    () =>
      form.handleSubmit(async (data) => {
        await login(data.email, data.password);
      }),
    []
  );
  return (
    <div className={styles.host}>
      <Form onSubmit={onSubmit}>
        <Form.Field>
          <label>E-Mail</label>
          <input
            type="email"
            placeholder="peterchen@fakeos.de"
            {...form.register('email')}
          />
        </Form.Field>
        <Form.Field>
          <label>Passwort</label>
          <input
            placeholder="123456"
            type="password"
            {...form.register('password')}
          />
        </Form.Field>
        <Button type="submit">Anmelden</Button>
      </Form>
    </div>
  );
};
