import React, { ComponentType } from 'react';
import { Menu } from '../menu/menu.component';
import styles from './page.module.scss';
interface PageProps {
  title?: string;
}

export const Page: ComponentType<PageProps> = (props) => {
  const { children, title } = props;
  return (
    <Menu>
      <div className={styles.host}>
        {title && <h1 className={styles.pageTitle}>{title}</h1>}
        <div className={styles.content}>{children}</div>
      </div>
    </Menu>
  );
};
