import { useApiCollection } from '@/features/api/api.hooks';
import { Page } from '@/features/frame/page/page.component';
import React, { ComponentType } from 'react';
import { Table } from 'semantic-ui-react';

interface ListProps {}

export const List: ComponentType<ListProps> = (props) => {
  const { data: productions } =
    useApiCollection<API.Production>('admin/productions');

  return (
    <Page title="Kunden">
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Zuletzt aktualisiert</Table.HeaderCell>
            <Table.HeaderCell>Abos</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {productions.map((production) => (
            <Table.Row key={production.id}>
              <Table.Cell>{production.title}</Table.Cell>
              <Table.Cell>{production.updatedAt}</Table.Cell>
              <Table.Cell>{production.subscriptions.length}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Page>
  );
};
