import React, {
  ComponentProps,
  ComponentType,
  memo,
  ReactNode,
  useEffect,
} from 'react';
import {
  Controller,
  DeepPartial,
  FormProvider,
  useFormContext,
} from 'react-hook-form';
import {
  UnpackNestedValue,
  UseFormReturn,
} from 'react-hook-form/dist/types/form';
import { Form as SemanticForm } from 'semantic-ui-react';
import {
  InputField,
  InputFieldProps,
} from './input-field/input-field.component';
// import { NumberFormatProps } from 'react-number-format';
// import { ImageSelect } from 'shared/ui/form/image-select/image-select.component';

export type StrictFormFieldProps = {
  name: string;
  defaultValue?: string | boolean | number;
  help?: ReactNode;
  helpTitle?: string;
};

export type HookedFormFieldProps<Props> = Props & StrictFormFieldProps;

export type ControlledFormProps<FieldValues> = {
  children: any;
  style?: any;
  form: UseFormReturn<FieldValues>;
  values?: UnpackNestedValue<DeepPartial<FieldValues>> | null;
};

function ControlledForm<FieldValues>(props: ControlledFormProps<FieldValues>) {
  const { children, form, values, style } = props;

  useEffect(() => {
    if (values) {
      form.reset(values);
    }
  }, [values]);

  return (
    <form style={style} onSubmit={(e) => e.preventDefault()}>
      <FormProvider {...form}>
        <SemanticForm>
          <>{children}</>
        </SemanticForm>
      </FormProvider>
    </form>
  );
}

function ControlledInput(props: HookedFormFieldProps<InputFieldProps>) {
  const { control } = useFormContext<any>();
  const { name, defaultValue, ...rest } = props;

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => {
        return (
          <InputField {...field} {...rest} error={fieldState.error?.message} />
        );
      }}
    />
  );
}

type PropsComparator<C extends ComponentType> = (
  prevProps: Readonly<ComponentProps<C>>,
  nextProps: Readonly<ComponentProps<C>>
) => boolean;

function typedMemo<C extends ComponentType<any>>(
  Component: C,
  propsComparator?: PropsComparator<C>
) {
  return memo(Component, propsComparator) as any as C;
}

export const Form = Object.assign(ControlledForm, {
  Input: typedMemo(ControlledInput),
});
