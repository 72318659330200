import { number, object, string } from 'yup';

export default object({
  email: string().email().required(),
  firstName: string().required().min(3),
  lastName: string().required().min(3),
  gender: number().oneOf([1, 2]).optional(),
  company: string().optional(),
  streetAndNumber: string().optional(),
  postalCode: string().optional(),
  city: string().optional(),
  country: string().optional(),
  dateOfBirth: string().optional(),
});
