import React, { ComponentType } from 'react';
import { Form, Input, InputOnChangeData } from 'semantic-ui-react';

export interface InputFieldProps {
  error?: string;
  label?: string;
  name?: string;
  value?: string;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) => void;
}

export const InputField: ComponentType<InputFieldProps> = (props) => {
  const { error, label, ...fieldProps } = props;
  return (
    <Form.Field
      {...fieldProps}
      error={error ? { content: error, pointing: 'below' } : undefined}
      control={Input}
      label={label}
    />
  );
};
