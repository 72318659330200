import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';

const axiosInstance = axios.create({
  withCredentials: true,
});

interface Api<T> {
  get: (path: string) => Promise<T[]>;
  getOne: (path: string, id?: number | string) => Promise<T>;
  post: (path: string, data?: any) => Promise<any>;
}

export function useApi<T = any>(): Api<T> {
  const get = useCallback(async (path: string) => {
    const result = await axiosInstance.get<T[]>(
      `${process.env.BACKEND_URI}/${path}`
    );
    return result?.data || [];
  }, []);

  const getOne = useCallback(async (path: string, id?: number | string) => {
    const result = await axiosInstance.get<T>(
      `${process.env.BACKEND_URI}/${path}${id ? `/${id}` : ''}`
    );
    return result?.data;
  }, []);

  const post = useCallback(async (path: string, data?: any) => {
    const result = await axiosInstance.post(
      `${process.env.BACKEND_URI}/${path}`,
      data
    );
    return result;
  }, []);

  return {
    get,
    getOne,
    post,
  };
}

export function useApiCollection<T>(path: string) {
  const [data, setData] = useState<T[]>([]);
  const { get } = useApi();

  useEffect(() => {
    get(path).then((response) => setData(response));
  }, [path]);
  return { data };
}

interface UseApiEntityOptions {
  id?: number | string;
  skip?: boolean;
}

export function useApiEntity<T>(path: string, options?: UseApiEntityOptions) {
  const [data, setData] = useState<T>();
  const { getOne, post } = useApi();

  const update = useCallback(
    (data, id: number) => {
      post(`${path}/${id}`, data);
    },
    [path]
  );

  useEffect(() => {
    if (!options?.skip) {
      getOne(path, options?.id).then((response) => setData(response));
    }
  }, [path, options?.id, options?.skip]);
  return { data, update };
}
