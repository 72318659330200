import { useApiCollection } from '@/features/api/api.hooks';
import { Page } from '@/features/frame/page/page.component';
import React, { ComponentType } from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'semantic-ui-react';

interface ListProps {}

export const List: ComponentType<ListProps> = (props) => {
  const { data: customers } = useApiCollection<API.Customer>('admin/customers');

  return (
    <Page title="Kunden">
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Kunde</Table.HeaderCell>
            <Table.HeaderCell>E-Mail</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {customers.map((customer) => (
            <Table.Row key={customer.id}>
              <Table.Cell>
                <Link to={`/customers/${customer.id}`}>
                  {customer.firstName} {customer.lastName}
                </Link>
              </Table.Cell>
              <Table.Cell>{customer.email}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Page>
  );
};
