// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LU04Vt\\+l1p0dMpau5eMpLg\\=\\={display:flex;height:100vh;flex:1;overflow:auto}.LU04Vt\\+l1p0dMpau5eMpLg\\=\\= h1.d\\+YeVI841l1zk212OKbswA\\=\\={position:absolute;right:20px;top:20px;margin:0;text-transform:uppercase}.LU04Vt\\+l1p0dMpau5eMpLg\\=\\= .we4\\+oiULrQSDpoVkWpPxzg\\=\\={margin-top:80px;margin-left:10px;margin-right:10px;width:100%}", "",{"version":3,"sources":["webpack://./src/features/frame/page/page.module.scss"],"names":[],"mappings":"AAAA,6BACE,YAAA,CACA,YAAA,CACA,MAAA,CACA,aAAA,CAEA,4DACE,iBAAA,CACA,UAAA,CACA,QAAA,CACA,QAAA,CACA,wBAAA,CAGF,0DACE,eAAA,CACA,gBAAA,CACA,iBAAA,CACA,UAAA","sourcesContent":[".host {\n  display: flex;\n  height: 100vh;\n  flex: 1;\n  overflow: auto;\n\n  h1.pageTitle {\n    position: absolute;\n    right: 20px;\n    top: 20px;\n    margin: 0;\n    text-transform: uppercase;\n  }\n\n  .content {\n    margin-top: 80px;\n    margin-left: 10px;\n    margin-right: 10px;\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"host": "LU04Vt+l1p0dMpau5eMpLg==",
	"pageTitle": "d+YeVI841l1zk212OKbswA==",
	"content": "we4+oiULrQSDpoVkWpPxzg=="
};
export default ___CSS_LOADER_EXPORT___;
